import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';
import MobileDetect from 'mobile-detect';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

var marquee = require('jquery.marquee');

var siteGlobal = {
  initReady: function () {
  	this.mobileDetect();
  },
  initLoad: function () {
    this.customSelect();
  	this.lazyLoad();
    this.menuInit();
    this.homeCarousel();
    this.homeVini();
    this.elencoVini();
    this.bottleAnim();

    this.scrollToLoaded();

    this.homeIntroAnim();
    this.homeLavoroAnim();
    this.homeLavoroParallax();
    this.homeNewsAnim();

    this.aziendaIntroAnim();
    this.azIntervAnim();
    this.azInterParallax();

    this.certAnim();

    this.homeViniAnim();
  },
  initScroll: function(){
  },
  scrollToLoaded() {

    if (window.location.pathname == '/vini/' || window.location.pathname == '/en/wines/') {
      $( document ).ready(function() {
        var hashcode = window.location.hash;
        if (hashcode) {
          $(window).scrollTop($(hashcode).offset().top)
        }
      });
    }
  },
  mobileDetect: function() {
    var detector = new MobileDetect(window.navigator.userAgent);
  	if (detector.mobile()){
      $('html').addClass('is-touch');
    } else {
      $('html').addClass('no-touch');
    }
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header').toggleClass('opened');
    })
  },
  customSelect: function(){
    $('.wpcf7 select').each(function(){
        var $this = $(this), 
          numberOfOptions = $(this).children('option').length,
          classList = $(this).attr('class').split(',');;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options ' + classList
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('active').next('ul.select-options').slideToggle(150);
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
        });
      
        $listItems.on('click', function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $(this).parent().siblings('.select-styled').addClass('sel');
        });
    });
  },
  homeCarousel: function(){
    if ($('.homeGal__car').length) {
      $('.homeGal__car').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        speed: 600,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        rtl: true,
        lazyLoad: 'progressive',
        responsive: [ 
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        }
        ]
      });
    }
  },
  homeVini: function(){
    if ($('.homeVini__gall').length) {
      $('.homeVini__gall').slick({
        slidesToShow:1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        speed: 600,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        lazyLoad: 'progressive'
      });
    }

  },
  elencoVini: function(){
    if ($('.wineBlock__car').length) {
      $('.wineBlock__car').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        speed: 600,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        lazyLoad: 'on demand',
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    }
  },
  bottleAnim: function(){
    /* 
      230 - margin top
      250 - footer height
      200 - margin bottom sheet
      700 - bottle height
    */
    
    if ($(window).outerWidth(true) > 767) { 
      //var height = $('.wineSheet__wrap').outerHeight(true);
      var height =  $('.footer').offset().top - 360 - 290;
      //console.log(height) ;
      var controller = new ScrollMagic.Controller(),
          scene = new ScrollMagic.Scene({triggerElement: "#wine_sheet_pin", duration: height}).setPin("#feat").addTo(controller);
    }
  },
  homeIntroAnim: function(){
    setTimeout(function(){
      var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      homeEls = $('.homeVisual .txtImgBlock__sub,.homeVisual .txtImgBlock__title,.homeVisual .txtImgBlock__text,.homeVisual .txtImgBlock__btn '),
      homeAnim = tl.staggerTo(homeEls, .5, {y: 0, opacity: 1, ease: Sine.ease}, .15),
      scene = new ScrollMagic.Scene({triggerElement: $('#homeVisual_span')[0]}).setTween(homeAnim).addTo(controller);
    }, 1000)
  },
  homeLavoroAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      lavEls = $('.homeLavoro__imgWrap img, .homeLavoro .txtImgBlock__title,.homeLavoro .txtImgBlock__text,.homeLavoro .txtImgBlock__btn '),
      lavAnim = tl.staggerTo(lavEls, .5, {y: 0, opacity: 1, ease: Sine.ease}, .15),
      scene = new ScrollMagic.Scene({triggerElement: $('#homeLavoro_span')[0]}).setTween(lavAnim).addTo(controller);
  },
  homeLavoroParallax: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      lavParEl = $('.homeLavoro__img--1');

      if ($(window).outerWidth(true) > 767) {
        var lavAnim = tl.to(lavParEl, .5, {top: -200, ease: Sine.ease});
      } else {
        var lavAnim = tl.to(lavParEl, .5, {top: -50, ease: Sine.ease});
      }
      
      var scene = new ScrollMagic.Scene({duration: $(window).outerHeight(true), triggerElement: $('#homeLavoro_span')[0]}).setTween(lavAnim).addTo(controller);
  },
  homeViniAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      viniEls = $('.homeVini .txtImgBlock__title,.homeVini .txtImgBlock__text,.homeVini .txtImgBlock__btn '),
      viniAnim = tl.staggerTo(viniEls, .5, {y: 0, opacity: 1, ease: Sine.ease}, .15),
      scene = new ScrollMagic.Scene({triggerElement: $('#homeVini_span')[0]}).setTween(viniAnim).addTo(controller);
  },
  homeNewsAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      newsBlocks = $('.homeNews .homeNews__block '),
      newsAnim = tl.staggerTo(newsBlocks, .5, {y: 0, opacity: 1, ease: Sine.ease}, .15),
      scene = new ScrollMagic.Scene({triggerElement: $('#homeNews_span')[0]}).setTween(newsAnim).addTo(controller);
  },
  aziendaIntroAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      azIntroEls = $('.azIntro .azIntro__tit, .azIntro .azIntro__text, .azIntro .azIntro__img, .azIntroBot .azIntro__tit, .azIntroBot .azIntro__text'),
      azInAnim = tl.staggerTo(azIntroEls, .5, {y: 0, opacity: 1, ease: Sine.ease}, .15),
      scene = new ScrollMagic.Scene({triggerElement: $('#azIntro_span')[0]}).setTween(azInAnim).addTo(controller);
  },
  azIntervAnim: function(){
      $('.elInt__block').each(function(){
          var controller = new ScrollMagic.Controller(),
          tl = new TimelineMax(),
          trig = $(this).find('.elInt__span'),
          tit = $(this).find('.elInt__tit, .elInt__name, .elInt__q, .elInt__a'),
          intAnim = tl.staggerTo(tit, .5, {y: 0, opacity: 1, ease: Sine.ease}, .1),
          scene = new ScrollMagic.Scene({triggerElement: $(trig)[0]}).setTween(intAnim).addTo(controller);
      })
  },
  azInterParallax: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      lavParEl = $('.elInt__gallWrap--1 img');

      if ($(window).outerWidth(true) > 767) {
        var lavAnim = tl.to(lavParEl, .5, {top: -140, ease: Sine.ease}, );
      } else {
        var lavAnim = tl.to(lavParEl, .5, {top: -50, ease: Sine.ease});
      }

      var scene = new ScrollMagic.Scene({duration: $(window).outerHeight(true), triggerElement: $('.elInt__span--0')[0]}).setTween(lavAnim).addTo(controller);
  },
  certAnim: function(){
    $('.lavSec').each(function(){
      var controller = new ScrollMagic.Controller(),
        tl = new TimelineMax(),
        trig = $(this).find('.cert_span'),
        tit = $(this).find('.cert__blockTit, .cert__blockTxt, .cert__blockImg'),
        certAnim = tl.staggerTo(tit, .4, {y: 0, opacity: 1, ease: Sine.ease}, .1),
        scene = new ScrollMagic.Scene({triggerElement: $(trig)[0]}).setTween(certAnim).addTo(controller);
      

      if ($(window).outerWidth(true) > 767) {
        var controller2 = new ScrollMagic.Controller(),
          tl2 = new TimelineMax(),
          certImg = $(this).find('.cert__block--right img'),
          certParAnim = tl2.to(certImg, .5, {top: -140, ease: Sine.ease}, ),
          scene = new ScrollMagic.Scene({duration: $(window).outerHeight(true), triggerElement: $(trig)[0]}).setTween(certParAnim).addTo(controller2);
      }
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());